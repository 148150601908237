









































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { StakingViewModel } from "../viewmodels/staking-viewmodel";

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: StakingViewModel;
}
